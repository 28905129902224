import React, { useState, useEffect } from 'react';
import electionAPIData from "../../utils/election-api";
import colorFeeds from "../Data/coloursData.json";
import "./NationalPicture.css";


const NationalPicture = ({ election }) => {
  const [electionData, setElectionData] = useState(null);
  const [electionType, setElectionType] = useState([]);
  const [countStatus, setCountStatus] = useState([]);
  const [constituencyData, setConstituencyData] = useState([]);
  const [turnoutData, setTurnoutData] = useState([]);
  const [seatsFilledData, setSeatsFilledData] = useState([]);
  const [maxConstituencies, setMaxConstituencies] = useState([]);
  const [maxSeats, setMaxSeats] = useState([]);

  useEffect(() => {
    // Need to add in code to fetch data from Spreadsheet API (locals)

    // Form query parameters
    let endpointParams = election + "/nationalpage";

    electionAPIData(endpointParams)
      .then((data) => {
        setElectionType(data.type);
        setSeatsFilledData(data.seatsfilled);
        setCountStatus(data.electionStatus);
        setTurnoutData(data.turnout);
        setConstituencyData(data.constituenciescompleted);
        setElectionData(data.voteShareOrder); // Eg feed: https://qa.irishtimes.com/election-api/api/public/v1/elections/qaeur19/nationalpage
        setMaxSeats(data.totalSeats);
        setMaxConstituencies(data.constituencyCount);
      })
      .catch((err) => {
        console.log(err.message);
      });

    // Fetch data - end
  }, []);

  const PartyDetails = ({ electionData }) => {
    return (
      <div className="parties-container">
        {Object.keys(electionData).map((partyKey) => (
          <div
            key={partyKey}
            className="party-frame"
            style={{
              backgroundColor: colorFeeds[0][partyKey.toLowerCase()],
            }}
          >
            <div>
              <span className="party-code">{electionData[partyKey].party}</span>
              <div>
                <span className="party-number">
                  {electionData[partyKey].seats}
                </span>
              </div>
              <span className="first-pref-text">
                1<sup>st</sup> pref{" "}
                <span>
                  {electionData[partyKey].firstpreference
                    ? electionData[partyKey].firstpreference.toFixed(2)
                    : "..."}
                  %
                </span>
              </span>
            </div>
          </div>
        ))}
      </div>
    );
  };

  const ElectionStatus = (props) => {
    if (props.countStatus === "C") {
      return (
        <div className="status-container">
          <div className="icon complete">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
              width="100%"
            >
              <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
            </svg>
          </div>
          <div className="text complete">COMPLETE</div>
        </div>
      );
    } else if (props.countStatus === "U") {
      return (
        <div className="status-container">
          <div className="icon counting">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16 14"
              fill="none"
              width="100%"
            >
              <path
                d="M7.592 10.128C7.848 10.512 8 10.968 8 11.464V12.4C8 12.952 7.552 13.4 7 13.4H1C0.448 13.4 0 12.952 0 12.4V11.464C0 10.968 0.152 10.504 0.408 10.12C0.84 9.48001 1.576 9.14401 2.328 9.14401C2.576 9.14401 2.824 9.18401 3.072 9.25601C3.368 9.34401 3.68 9.39201 4 9.39201C4.32 9.39201 4.632 9.34401 4.928 9.25601C5.168 9.18401 5.424 9.14401 5.672 9.14401C6.424 9.14401 7.16 9.48001 7.592 10.128ZM1.6 6.20001C1.6 4.87201 2.672 3.80001 4 3.80001C5.328 3.80001 6.4 4.87201 6.4 6.20001C6.4 7.52801 5.328 8.60001 4 8.60001C2.672 8.60001 1.6 7.52801 1.6 6.20001ZM16 1.84001V9.76001C16 10.448 15.464 11 14.8 11H8.8V9.80001H14.8V1.80001H5.2V3.24001C5.2 3.24001 5.128 3.22401 5.096 3.20801C5 3.16801 4.896 3.13601 4.792 3.11201C4.664 3.08001 4.528 3.05601 4.392 3.04001C4.28 3.01601 4.16 3.00001 4 3.00001V1.84001C4 1.15201 4.536 0.600006 5.2 0.600006H14.8C15.464 0.600006 16 1.15201 16 1.84001ZM9.576 7.02401L8.2 5.64801L7.152 6.69601C7.176 6.52801 7.2 6.36801 7.2 6.20001C7.2 5.82401 7.128 5.47201 7.008 5.14401L7.776 4.37601C8.008 4.14401 8.392 4.14401 8.624 4.37601L10 5.75201L11.376 4.37601L10.768 3.76801C10.48 3.48801 10.688 3.00001 11.088 3.00001H13.304C13.472 3.00001 13.608 3.13601 13.608 3.30401V5.52001C13.608 5.92001 13.12 6.12001 12.84 5.84001L12.232 5.23201L10.432 7.03201C10.2 7.26401 9.816 7.26401 9.584 7.03201L9.576 7.02401Z"
                fill="#E07000"
              />
            </svg>
          </div>
          <div className="text counting">UNDER WAY</div>
        </div>
      );
    } else {
      return (
        <div className="status-container">
          <div className="icon pending">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              width="100%"
            >
              <path d="M386.3 160H336c-17.7 0-32 14.3-32 32s14.3 32 32 32H464c17.7 0 32-14.3 32-32V64c0-17.7-14.3-32-32-32s-32 14.3-32 32v51.2L414.4 97.6c-87.5-87.5-229.3-87.5-316.8 0s-87.5 229.3 0 316.8s229.3 87.5 316.8 0c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0c-62.5 62.5-163.8 62.5-226.3 0s-62.5-163.8 0-226.3s163.8-62.5 226.3 0L386.3 160z" />
            </svg>
          </div>
          <div className="text pending">PENDING</div>
        </div>
      );
    }
  };

  const ShowCeannComhairleText = (props) => {
    let ceannComhaairleText;

    if (props.electionType === "general" && props.seatsFilledData <= 5) {
      ceannComhaairleText = (
        <div className="ceann-comharile-text">
          * The Ceann Comhairle is automatically re-elected
        </div>
      );
    }

    return ceannComhaairleText;
  };

  const MiscInfo = (props) => {
    return (
      <div className="summary-container">
        <div className="summary-item">
          <span className="summary-figure">
            {props.seatsFilledData} / {props.maxSeats}{" "}
          </span>
          <span>Seats filled</span>
        </div>
        <div className="summary-item">
          <span className="summary-figure">
            {props.constituencyData} / {props.maxConstituencies}{" "}
          </span>
          <span>Constituencies completed</span>
        </div>
        <div className="summary-item">
          <span className="summary-figure">
            {props.turnoutData ? props.turnoutData : "..."}%{" "}
          </span>{" "}
          <span>Turnout</span>
        </div>
      </div>
    );
  };

  return (
    !!electionData && (
      <div className="NationalPicture">
        <ElectionStatus countStatus={countStatus} />
        <PartyDetails electionData={electionData} />
        <MiscInfo
          electionType={electionType}
          maxSeats={maxSeats}
          maxConstituencies={maxConstituencies}
          seatsFilledData={seatsFilledData}
          constituencyData={constituencyData}
          turnoutData={turnoutData}
        />
        <ShowCeannComhairleText
          electionType={electionType}
          seatsFilledData={seatsFilledData}
        />
      </div>
    )
  );
};

export default NationalPicture;
