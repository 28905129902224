import React, { useState, useEffect, useRef } from "react";
import { motion, animate } from "framer-motion";
import colorConfig from "../Data/coloursData.json";
import electionAPIData from "../../utils/election-api";
import "./CountStory.css";

const BAR_WIDTH = 35;
const BAR_OFFSET = 15;

const CountStory = ({ election, constituency }) => {
  const [countData, setCountData] = useState(null);
  const [currentCount, setCurrentCount] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [shouldReset, setShouldReset] = useState(true);

  const isNullElection = countData?.turnout <= 0;

  const fetchElectionData = async () => {
    try {
      const votingCountData = await electionAPIData(
        `${election}/constituencies/${constituency}/fulldata`
      );

      return Promise.resolve(votingCountData);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  useEffect(() => {
    fetchElectionData()
      .then((data) => {
        setCountData(data);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    let interval;
    if (isPlaying && !!countData) {
      interval = setInterval(() => {
        setCurrentCount((prevCount) => {
          if (prevCount >= countData.meta.count - 1) {
            setIsPlaying(false);
            return prevCount;
          }
          return prevCount + 1;
        });
      }, 3000);
    }
    return () => !!interval && clearInterval(interval);
  }, [isPlaying, countData?.meta.count]);

  // Get Max Vote from table data
  const maxVotes = !!countData?.tableData
    ? Math.max(
        ...countData.tableData.map((candidate) =>
          Math.max(...Object.values(candidate).filter((value) => !isNaN(value)))
        )
      )
    : 0;

  // Hash Map of Candidates with Candidate/Votes data at each count stage
  const votingTransitionMap = {};

  // List of number elected of candidates at each stage of the count
  const electedPerCount = [0];

  //List of candidates excluded from next counr
  const excludedCandidates = [];

  countData?.countresults.forEach((countRes, countIdx) => {
    const votesObjArr = Object.values(countRes).filter((countResItem) => {
      return !!countResItem?.hash;
    });

    // Sort candidates by votes
    const sortedVotesObjArr = [...votesObjArr, ...excludedCandidates].sort(
      (a, b) => b.votes - a.votes
    );

    if (countIdx > 0) {
      electedPerCount[countIdx] = electedPerCount?.[countIdx - 1];
    }

    // Add elected or eliminated candidates to excludedCandidates
    votesObjArr.forEach((candidateData) => {
      if (candidateData.elected === 1 || candidateData.eliminated === 1) {
        candidateData.elected === 1 && electedPerCount[countIdx]++;
        excludedCandidates.push(candidateData);
      }
    });

    // Sort candidates by votes
    sortedVotesObjArr.forEach((voteObj, itemIdx) => {
      if (countIdx === 0) {
        votingTransitionMap[voteObj.hash] = {
          party: voteObj.code,
          name: voteObj.name,
          votesArr: [voteObj.votes],
          votesRankArr: [itemIdx],
          electedArr: [voteObj.elected],
          eliminatedArr: [voteObj.eliminated],
        };
      } else if (votingTransitionMap?.[voteObj.hash]) {
        votingTransitionMap[voteObj.hash].votesArr.push(voteObj.votes);
        votingTransitionMap[voteObj.hash].votesRankArr.push(itemIdx);
        votingTransitionMap[voteObj.hash].electedArr.push(voteObj.elected);
        votingTransitionMap[voteObj.hash].eliminatedArr.push(
          voteObj.eliminated
        );
      } else {
        votingTransitionMap[voteObj.hash] = {
          party: voteObj.code,
          name: voteObj.name,
          votesArr: [voteObj.votes],
          votesRankArr: [countIdx],
          electedArr: [voteObj.elected],
          eliminatedArr: [voteObj.eliminated],
        };
      }
    });
  });

  // Animation Controls
  const togglePlay = () => setIsPlaying(!isPlaying);
  const moveForward = () => {
    setIsPlaying(false);
    setCurrentCount((prevCount) => {
      return prevCount <= countData.meta.count - 1 ? prevCount + 1 : prevCount;
    });
  };
  const moveBackward = () => {
    setIsPlaying(false);
    setCurrentCount((prevCount) => {
      return prevCount > 0 ? prevCount - 1 : prevCount;
    });
  };
  const reset = () => {
    setIsPlaying(false);
    setCurrentCount(0);
    setShouldReset((s) => !s);
  };

  // Chart Dimension Fns
  const getBarWidth = (votes) => `${(votes * 100) / (1.125 * maxVotes)}%`;
  const formatVotes = (votes) => votes?.toLocaleString();

  const candidateCount = Object.keys(votingTransitionMap).length;

  const electedLine = countData?.meta?.quota && (
    <motion.div
      className="elected-line"
      style={{
        opacity: electedPerCount[currentCount] > 0 ? 1 : 0.4,
        color: electedPerCount[currentCount] > 0 ? "#333" : "#000",
      }}
      initial={{
        top: 0,
      }}
      animate={{
        transition: { duration: 0.5 },
        top:
          (BAR_WIDTH + BAR_OFFSET) * electedPerCount[currentCount] +
          BAR_OFFSET / 2,
      }}
    >
      <div className="elected-text">Elected</div>
    </motion.div>
  );

  const quotaLine = countData?.meta?.quota && (
    <div
      className="quota-line"
      style={{
        left: getBarWidth(countData?.meta.quota),
        height: candidateCount * (BAR_WIDTH + BAR_OFFSET) + 15,
      }}
    >
      <div className="quota-text">
        Quota: {formatVotes(countData?.meta.quota)}
      </div>
    </div>
  );

  return (
    countData && (
      <div className="CountStory">
        <div className="controls-group">
          <button
            className="control-btn"
            disabled={isNullElection || currentCount < 1}
            onClick={moveBackward}
          >
            <IconWrapper>
              <path d="M.5 3.5A.5.5 0 0 0 0 4v8a.5.5 0 0 0 1 0V8.753l6.267 3.636c.54.313 1.233-.066 1.233-.697v-2.94l6.267 3.636c.54.314 1.233-.065 1.233-.696V4.308c0-.63-.693-1.01-1.233-.696L8.5 7.248v-2.94c0-.63-.692-1.01-1.233-.696L1 7.248V4a.5.5 0 0 0-.5-.5" />
            </IconWrapper>
            <span>Back</span>
          </button>
          <button
            className="control-btn"
            onClick={togglePlay}
            disabled={
              isNullElection || currentCount >= countData.meta.count - 1
            }
          >
            {isPlaying ? (
              <>
                <IconWrapper>
                  <path d="M5.5 3.5A1.5 1.5 0 0 1 7 5v6a1.5 1.5 0 0 1-3 0V5a1.5 1.5 0 0 1 1.5-1.5m5 0A1.5 1.5 0 0 1 12 5v6a1.5 1.5 0 0 1-3 0V5a1.5 1.5 0 0 1 1.5-1.5" />
                </IconWrapper>
                <span>Pause</span>
              </>
            ) : (
              <>
                <IconWrapper>
                  <path d="m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393" />
                </IconWrapper>
                <span>Play</span>
              </>
            )}
          </button>
          <button
            className="control-btn"
            disabled={
              isNullElection || currentCount >= countData.meta.count - 1
            }
            onClick={moveForward}
          >
            <IconWrapper>
              <path d="M15.5 3.5a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-1 0V8.753l-6.267 3.636c-.54.313-1.233-.066-1.233-.697v-2.94l-6.267 3.636C.693 12.703 0 12.324 0 11.693V4.308c0-.63.693-1.01 1.233-.696L7.5 7.248v-2.94c0-.63.693-1.01 1.233-.696L15 7.248V4a.5.5 0 0 1 .5-.5" />
            </IconWrapper>
            <span>Next</span>
          </button>
          <button
            className="control-btn"
            disabled={isNullElection}
            onClick={reset}
          >
            <IconWrapper>
              <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41m-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9" />
              <path
                fillRule="evenodd"
                d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5 5 0 0 0 8 3M3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9z"
              />
            </IconWrapper>

            <span>Reset</span>
          </button>
          <span className="count-info">
            Count:{" "}
            <span>
              {isNullElection
                ? "Pending"
                : `${currentCount + 1} of ${countData.meta.count}`}
            </span>
          </span>
        </div>
        <div className="story-container">
          {electedLine}
          <div
            className="story-wrapper"
            style={{
              gap: BAR_OFFSET,
            }}
          >
            <div className="candidate-container">
              <div className="candidate-name" aria-hidden="true"></div>
              <div
                style={{
                  flex: 1,
                  position: "relative",
                }}
              >
                {quotaLine}
              </div>
            </div>
            {Object.entries(votingTransitionMap).map((val) => {
              const [hash, transitionObj] = val;
              const { name, party, votesArr, votesRankArr } = transitionObj;

              const currentRankVal = votesRankArr[currentCount];

              const calcVotes = getBarWidth(votesArr[currentCount]);

              return (
                <motion.div
                  layout
                  className="candidate-container"
                  key={hash}
                  style={{
                    order: currentRankVal,
                  }}
                  transition={{
                    layout: { duration: 1 },
                  }}
                >
                  <span
                    className="candidate-name"
                    dangerouslySetInnerHTML={{ __html: name }}
                  />
                  <div
                    style={{
                      flex: 1,
                      position: currentRankVal === 0 ? "relative" : "static",
                    }}
                  >
                    <motion.span
                      key={shouldReset}
                      initial={{ width: 0 }}
                      animate={{
                        width: calcVotes,
                        transition: { duration: 1.5 },
                      }}
                      className="votes-bar"
                      style={{
                        backgroundColor:
                          colorConfig[0]?.[party.toLowerCase()] || "#000",
                      }}
                    >
                      <Counter
                        from={currentCount > 0 ? votesArr[currentCount - 1] : 0}
                        to={votesArr?.[currentCount] || 0}
                        duration={1.5}
                      />
                    </motion.span>
                  </div>
                </motion.div>
              );
            })}
          </div>
        </div>
      </div>
    )
  );
};

export default CountStory;

function Counter({ from, to, duration }) {
  const nodeRef = useRef();

  useEffect(() => {
    const node = nodeRef.current;

    const controls = animate(from, to, {
      duration,
      onUpdate(value) {
        node.textContent = Math.round(Number(value)).toLocaleString("en-IE");
      },
    });

    return () => controls.stop();
  }, [from, to]);

  return <span ref={nodeRef} />;
}

function IconWrapper({ children }) {
  return (
    <svg
      className="control-icon"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 16 16"
    >
      {children}
    </svg>
  );
}
