import React, { useState, useEffect } from "react";
import "./CardMetadata.css";
import electionAPIData from "../../utils/election-api";
import colorFeeds from "../Data/coloursData.json";

const CardMetadata = ({ election, constituency }) => {
  const [partyColors, setPartyColors] = useState([]);
  const [constData, setConstData] = useState(null);
  const [metadata, setMetadata] = useState([]);

  const fetchConstituenciesData = async () => {
    try {
      const nationalPageData = await electionAPIData(
        `${election}/nationalpage`
      );
      const constituenciesData = await electionAPIData(
        `${election}/constituencies`
      );

      const constituencyMetaData = await electionAPIData(
        `${election}/constituencies/${constituency}/data`
      );

      return Promise.resolve({
        nationalPageData,
        constituenciesData,
        constituencyMetaData,
      });
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const matchConstsAndCeanComhairle = (
    constCode,
    nationalPageData,
    constituenciesData
  ) => {
    // Match constituency data from /nationalpage and static /constituencies by constituency code
    const staticData = constituenciesData.find(
      (constituency) => constituency.code === constCode
    );

    if (!staticData) return null;

    const npData = nationalPageData.constituencies.find(
      (constituency) => constituency.code === constCode
    );

    const matchedData = {
      ...staticData,
      ...(npData ? npData : {}),
    };

    // Update Cean Comhairle if available

    if (nationalPageData.ceanncomhairle?.constituencyCode === constCode) {
      const ceanncomhairleData = {
        name: nationalPageData.ceanncomhairle.name,
        party: nationalPageData.ceanncomhairle.party,
        firstpreference: 0,
        count: "NA",
      };

      if (matchedData.elected) {
        matchedData.elected.unshift(ceanncomhairleData);
      } else {
        matchedData.elected = [ceanncomhairleData];
      }
      matchedData.isCeanncomhairleConst = true;
    }

    return matchedData;
  };

  useEffect(() => {
    // Declare the party colours
    setPartyColors(colorFeeds);

    fetchConstituenciesData()
      .then(
        ({ nationalPageData, constituenciesData, constituencyMetaData }) => {
          const matchedData = matchConstsAndCeanComhairle(
            constituency,
            nationalPageData,
            constituenciesData
          );
          setConstData(matchedData);

          if (constituencyMetaData.length === 0) {
            setMetadata(matchedData);
          } else {
            setMetadata(constituencyMetaData);
          }
        }
      )
      .catch((err) => console.log(err.message));
  }, []);

  // Seats filled
  function Seats(props) {
    let seatsParty = [];
    let partyName = "";
    let seatsNumber = 0;

    // If this is the CC's constituency and people have been elected then use the seats object above
    // Note: The CC does not come through NERD as he's not up for re-election.....technically

    seatsNumber = props.totalseats;

    for (let i = 0; i < seatsNumber; i++) {
      if (props.elected[i]) {
        // If the seat is filled, use the party name for color
        partyName = props.elected[i].party.toLowerCase();
      } else {
        // If the seat is unfilled, set a default value for unfilled seats
        partyName = "unfilled";
      }

      // Set the party colour
      let electedBg = {
        backgroundColor: partyColors[0][partyName],
      };
      seatsParty.push(<div key={i} className="seats" style={electedBg}></div>);
    }
    return seatsParty;
  }

  // Metadata
  function Table(props) {
    return (
      <>
        <tr>
          <td>Electorate</td>
          <td className="metadata-td">
            {props.meta?.electorate
              ? props.meta.electorate.toLocaleString("en-US")
              : "..."}
          </td>
        </tr>
        <tr>
          <td>Quota</td>
          <td className="metadata-td">
            {props.meta?.quota
              ? props.meta.quota.toLocaleString("en-US")
              : "..."}
          </td>
        </tr>
        <tr>
          <td>Total Poll</td>
          <td className="metadata-td">
            {props.meta?.totalpoll
              ? props.meta.totalpoll.toLocaleString("en-US")
              : "..."}
          </td>
        </tr>
        <tr>
          <td>Spoiled</td>
          <td className="metadata-td">
            {props.meta?.spoiledvotes
              ? props.meta.spoiledvotes.toLocaleString("en-US")
              : "..."}
          </td>
        </tr>
        <tr>
          <td>Valid Poll</td>
          <td className="metadata-td">
            {props.meta?.totalvalidpoll
              ? props.meta.totalvalidpoll.toLocaleString("en-US")
              : "..."}
          </td>
        </tr>
        <tr>
          <td>Turnout</td>
          <td className="metadata-td">
            {props.meta?.turnout ? `${props.meta.turnout.toFixed(2)}%` : "..."}
          </td>
        </tr>
      </>
    );
  }

  return (
    constData && (
      <div className="CardMetadata">
        <div className="metadata">
          <table className="metadata-table">
            <tbody>
              <tr>
                <td>Seats</td>
                <td className="metadata-td">
                  <div className="seats-td">
                    <Seats
                      totalseats={
                        constData?.setseats ||
                        constData?.totalseats ||
                        constData.seats
                      }
                      elected={constData.elected ? constData.elected : []}
                    />
                  </div>
                </td>
              </tr>
              <Table meta={metadata} />
            </tbody>
          </table>
        </div>
      </div>
    )
  );
};

export default CardMetadata;
