import React, { useState, useEffect } from 'react';
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import electionAPIData from "../../utils/election-api";
import spreadsheetAPIData from "../../utils/spreadsheet-api";
import colorFeeds from "../Data/coloursData.json";
import './graph.css';

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend
);

// Declare variables needed later on
let backgroundColors;
let firstPreferenceData = [];
let labels = [];
let seatsData = [];
let returnedData;
let chartObject;
let hideSeats;
let hideFirstPref;
let isNULL;

const Graph = ({ election, constituency, mode }) => {
	const [electionData, setElectionData] = useState({});
	const [electionType, setElectionType] = useState({});
	const [seatsFilled, setSeatsFilled] = useState({});

	// Show / Hide the seat and first pref labels based on URL params
	if (mode === "seats") {
		chartObject = 0;
		hideSeats = false;
		hideFirstPref = true;
	} else if (mode === "firstpref") {
		chartObject = 1;
		hideSeats = true;
		hideFirstPref = false;
	}

	useEffect(() => {
		// Fetch data - start
		if (election === "local24") {
			// Spreadsheet function to fetch data for locals
			spreadsheetAPIData()
				.then(data => {
					returnedData = data.parties;

					// Check if the firspref field is returning the correct data
					for (let party in returnedData) {
						if (returnedData[party]["firstpreference"] === null) {
							returnedData[party]["firstpreference"] = 0;
						}
					}

					setElectionData(returnedData);
					if (data.turnout > 0) {
						isNULL = false;
					} else {
						isNULL = true;
					}
				})
				.catch(error => {
					console.error('Error:', error);
				});
		} else {
			// Form query parameters
			let endpointParams = '';

			if (constituency) {
				endpointParams = election + '/constituencies/' + constituency + '/fulldata';
			} else {
				endpointParams = election + '/nationalpage';
			}

			electionAPIData(endpointParams)
				.then((data) => {						
					setElectionType(data.type);
					setSeatsFilled(data.seatsfilled);
			
					if (constituency) {
						if (typeof data.meta.turnout !== "undefined" && data.meta.turnout > 0) {
							returnedData = data.voteShareOrder; //Eg feed: https://qa.irishtimes.com/election-api/api/public/v1/elections/qaeur19/constituencies/MW2/fulldata
							isNULL = false;
						} else {
							returnedData = data.nullStateParties;
							isNULL = true;
						}
					} else {
						if (data.turnout > 0) {
							returnedData = data.voteShareOrder; // Eg feed: https://qa.irishtimes.com/election-api/api/public/v1/elections/qaeur19/nationalpage
							isNULL = false;
						} else {
							returnedData = data.nullPartyStates;
							isNULL = true;
						}
					}
					setElectionData(returnedData);
				})
				.catch((err) => {
					console.log(err.message);
				});
		}
		// Fetch data - end
	}, []);

	// Adjust the values for european contituency
	if (constituency) {
		labels = Object.values(electionData).map(party => party.party);
		seatsData = Object.values(electionData).map(party => party.seats);
		firstPreferenceData = Object.values(electionData).map(party => party.firstpreference);
		for (let key in seatsData) {
			if (seatsData[key] === undefined) {
				seatsData[key] = 0;
			}
		}
	} else {
		labels = Object.keys(electionData);
		seatsData = Object.values(electionData).map(party => party.seats);
		firstPreferenceData = Object.values(electionData).map(party => party.firstpreference);
	}

	// Set the background colours
	const setColors = (returnedData) => {
		let colorArray = [];
		labels.forEach(returnedData => {
			colorArray.push(colorFeeds[0][returnedData.toLowerCase()]);
		});

		return colorArray;
	};
	backgroundColors = setColors(returnedData);


	// Seats and First Pref
	const floatingLabels = {
		id: 'floatingLabels',
		afterDatasetsDraw(chart, args, options) {
			// Retrieve data from chart object
			const { ctx, chartArea: { top, bottom, left, right }, scales: { x, y } } = chart;
			for (let i = 0; i < chart.config.data.datasets[chartObject].data.length; i++) {
				// Where on the x-axis do I want the labels to show?
				// For resizing, i'm calculating where to put the primary dat column based on a percentage of the chart width
				const primaryDataPercentage = 10;
				const secondaryDataPercentage = 80;
				const xPositionColumnOne = right - secondaryDataPercentage;
				const xPositionColumnTwo = right - primaryDataPercentage; // This column shows the primary data

				// Y position is based on where the row is
				const yPosition = chart.getDatasetMeta(chartObject).data[i].y;
				const yPositionText = chart.getDatasetMeta(chartObject).data[i].y + 5;
				const yPositionStart = chart.getDatasetMeta(chartObject).data[0].y;
				const paddingLeft = 0;

				// Get data from chart
				const seats = chart.config.data.datasets[0].data[i];
				const labels = chart.config.data.labels[i].toUpperCase();
				let firstPref = chart.config.data.datasets[1].data[i].toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });

				ctx.save();

				// Party Names - Background
				let backgroundHeight = chart.getDatasetMeta(chartObject).data[i].height;
				let backgroundWidth = 45;
				let backgroundY = chart.getDatasetMeta(chartObject).data[i].y;

				// x, y, width, height
				ctx.fillStyle = chart.getDatasetMeta(chartObject).data[i].options.backgroundColor;
				ctx.fillRect(paddingLeft, backgroundY - (backgroundHeight / 2), backgroundWidth, backgroundHeight);

				// Font size is calculated dynamically based on the width of the chart, and it's adjusted to be between a minimum of 11px and a maximum of 16px
				let fontSize = 16;

				let labelFont = 'Roboto Condensed';

				// Party Names - Label
				ctx.font = fontSize + 'px ' + labelFont;
				ctx.textAlign = 'left';
				ctx.fillStyle = 'white';
				let textWidth = ctx.measureText(labels).width;
				ctx.fillText(labels, paddingLeft + backgroundWidth / 2 - textWidth / 2, yPositionText);

				// Line under rect
				ctx.beginPath();
				ctx.moveTo(paddingLeft, yPosition + yPositionStart);
				ctx.lineTo(chart.chartArea.left, yPositionStart + yPosition)
				ctx.strokeStyle = '#E5E5E5';
				ctx.stroke();
				// Seats and First pref column labels
				ctx.textAlign = 'right';
				ctx.font = fontSize + 'px ' + labelFont;
				ctx.fillStyle = 'black';

				if (mode === 'seats') {
					//First Pref Label (Secondary)
					ctx.fillText(firstPref + '%', xPositionColumnOne, yPositionText);

					//Seats label (Primary)
					ctx.font = 'bold ' + fontSize + 'px ' + labelFont;
					ctx.fillText(seats, xPositionColumnTwo, yPositionText);
				} else if (mode === 'firstpref') {

					if (isNULL === true) {
						// Null Label (Primary)
						ctx.font = 'bold ' + fontSize + 'px ' + labelFont;
						ctx.fillStyle = 'black';
						ctx.fillText('---', xPositionColumnTwo, yPositionText);
					} else {
						// Seats Label (Secondary)
						ctx.fillText(seats, xPositionColumnOne, yPositionText);

						//First Pref Label (Primary)
						ctx.font = 'bold ' + fontSize + 'px ' + labelFont;
						ctx.fillText(firstPref + '%', xPositionColumnTwo, yPositionText);
					}
				}
			}
		}
	}

	const findHighestFirstPreference = (electionData) => {
		let highestFirstPrefParty = null;

		for (const party of Object.values(electionData)) {
			if (!highestFirstPrefParty || parseFloat(party.firstpreference) > parseFloat(highestFirstPrefParty.firstpreference)) {
				highestFirstPrefParty = party;
			}
		}
	
		return highestFirstPrefParty ? highestFirstPrefParty.firstpreference : null;
	};

	const findHighestSeats = (electionData) => {
		let highestSeatsParty = null;
		
		for (const party of Object.values(electionData)) {
			if (!highestSeatsParty || parseFloat(party.seats) > parseFloat(highestSeatsParty.seats)) {
				highestSeatsParty = party;
			}
		}
	
		return highestSeatsParty ? highestSeatsParty.seats : null;
	};

	const highestFirstPrefParty = findHighestFirstPreference(electionData);
	const highestSeats = findHighestSeats(electionData);

	function getMaxValue(highestFirstPrefParty, highestSeats, electionType) {
		let maxValue;
		let firstPrefMax = 70;
		let seatsMax = 0;

		if (election === "local24") {
			maxValue = (mode === "seats") ? 678 : firstPrefMax;
		}  
		
		if(electionType === "general"){
			firstPrefMax = highestFirstPrefParty * 1.75;
			seatsMax = highestSeats * 2;
			maxValue = (mode === "seats") ? seatsMax : firstPrefMax;
		} else if (electionType !== "general" && election !== "local24") {
			const constituencies = ["DB2", "IS2", "MW2"];
			if (constituencies.includes(constituency)) {
				maxValue = (mode === "seats") ? 3 : firstPrefMax;
			} else {
				maxValue = (mode === "seats") ? 10 : firstPrefMax;
			}
		}
		return maxValue;
	}

	const options = {
		plugins:
		{
			tooltip:
			{
				enabled: false, // Disable tooltips
			},
			legend:
			{
				display: false
			},
		},
		layout:
		{
			padding:
			{
				left: 45
			}
		},
		scales:
		{
			y:
			{
				ticks:
				{
					display: false,
					color: 'white',
					font: {
						weight: 'bold',
						size: '20px',
					},
					backdropColor: backgroundColors,
					showLabelBackdrop: true,
				}
			},
			x:
			{
				beginAtZero: true,
				max: getMaxValue(highestFirstPrefParty, highestSeats, electionType),
				grid:
				{
					display: false
				},
				ticks:
				{
					display: false
				}
			},
		},
		animation: false,
		indexAxis: 'y',
		responsive: true,
		maintainAspectRatio: false,
		type: 'bar',
	};

	const chartData = {
		labels,
		datasets: [
			{
				labels: 'Seats',
				data: seatsData,
				backgroundColor: backgroundColors,
				hidden: hideSeats
			},
			{
				labels: 'First Pref',
				data: firstPreferenceData,
				backgroundColor: backgroundColors,
				hidden: hideFirstPref
			},
		],

	};

	function BarHeader() {
		let middleHeader;
		let lastHeader;

		const firstPrefHeader = <p className='firstPrefHeader'>1<sup>ST</sup> PREF</p>
		const seatsHeader = <p>SEATS</p>
		const firstHeader = <p>PARTY</p>

		if (mode === 'seats') {
			middleHeader = firstPrefHeader;
			lastHeader = seatsHeader;
		}
		else if (mode === 'firstpref') {
			if (isNULL === true) {
				middleHeader = '';
				lastHeader = firstPrefHeader

			} else {
				middleHeader = seatsHeader
				lastHeader = firstPrefHeader
			}
		}

		return (
			<>
				<div className='bar-header'>
					{firstHeader}
					{middleHeader}
					{lastHeader}
				</div>
			</>
		)
	};

	function ShowCeannComhairleText() {
		let ceannComhaairleText;

		// CC's constitency is hard coded as we are not getting the CC data from the constituency election data returned from Nerd.
		if (constituency === 'KSO' || !constituency && electionType === 'general' && seatsFilled <= 5) {
			ceannComhaairleText =
				<div className="ceann-comharile-text">
					* The Ceann Comhairle is automatically re-elected
				</div>;
		}

		return ceannComhaairleText;
	}

	return (
		<div className="bar-chart-container">
			<BarHeader />
			<div className='chart-wrapper'>
				<Bar options={options} plugins={[floatingLabels]} data={chartData} />
			</div>
			<ShowCeannComhairleText />
		</div>
	);

};

export default Graph;
